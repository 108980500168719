import React, { useState } from "react"
import { Row, Col, Container, Button, Card, Image, Navbar, Modal, Form } from "react-bootstrap"
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import LazyLoad from 'react-lazyload';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Countdown from 'react-countdown';

export default function WaitinglistPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Layout pageInfo={{ pageName: "waitinglist" }}>
        <SEO title="Waiting List" keywords={[`Emotional Mastery`, `Emotional`, `Mastery`]} />
        <LazyLoad>
          <Navbar fixed="top" className="bg-navbar-gradient-em" variant="light" bg="light">
            <Container>
              <Navbar.Brand href="#home" className="text-white text-mobile-cd-sl">
                BONUS DISC 67% AKAN DITUTUP DALAM
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <Countdown className="text-white __cd-time-wl-em" date={'2021-08-29 18:00'} />
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </LazyLoad>
        {/* Section one */}
        <LazyLoad>
          <div className="jumbotron bg-em-primary mt-5">
            <Container >
              <Row className="__em-content-align-center">
                <Col lg={12} md={12} sm={12}>
                  <div className="__em-logo-emotional">
                    <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/brain-object.png" />
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="__em-mockup-device">
                    <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Logo-EM.png" />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__wrape-headline-em">
                    <p className="text-center text-white">
                      Penting: Bagaimana cara mengendalikan emosi agar berhenti <br /> menyakiti diri sendiri, orang lain dan pasangan?
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </LazyLoad>
        {/* end Section one */}
        {/* Section two */}
        <LazyLoad>
          <Container className="bg-em-secondary">
            <Row>
              <Col>
                <div className="__wrappe-text-better-em">
                  <h3 className="text-center">
                    Better Emotion Better Love Life
                  </h3>
                  <p className="text-center">
                    Suka tidak suka, kita sering menyampaikan emosi dengan cara yang salah.<br /> Entah dalam pekerjaan, hubungan sosial hingga hubungan romansa.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="__em-section-any-problem">
              <Col lg={12} xs={12}>
                <h2 className="text-center">Apakah kamu mengalaminya juga?</h2>
              </Col>
              <div className="__em-mx-section">
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Gak bisa menyampaikan emosi yang kamu rasakan</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Galau karena kenyataan selalu berbeda dengan ekspektasi</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Sedih, marah dan kecewa berlarut akibat masalah yang gak kunjung selesai</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Kesulitan mengontrol emosi hingga akhirnya kamu dan pasangan malah saling menyakiti</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Sering memendam emosi karena takut dianggap jahat jika mengungkapkannya</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Menyesal sering salah mengambil keputusan ketika sedang emosi</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="mt-5">
              <Col lg={12}>
                <Card className="py-3">
                  <Card.Body>
                    <div className="__join-waitinglist-em">
                      <h3 className="text-center">
                        <b>Join VIP Waiting List Emotional Mastery <br /> Sebelum 30 Agustus 2021!</b>
                      </h3>
                      <p className="text-center">
                        Untuk dapatkan BONUS 3 video series Emotional Mastery <br />
                        dan Diskon 67% Tiket VIP Emotional Mastery
                      </p>
                    </div>
                    <div className="_btn-wl-em d-flex justify-content-center">
                      <Button variant="primary" size="md" onClick={handleShow}>Ya Saya Mau Video Gratisnya</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="__margin-top-bottom">
              <Col lg={6} md={6} sm={12}>
                <div className="__em-img-girl-think-wl">
                  <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/women-think.jpg" />
                </div>
              </Col>
              <Col lg={6} md={5} sm={12}>
                <div className="__em-wrappe-wording">
                  <h2>
                    <b>STOP! PERINGATAN</b>
                  </h2>
                  <p>
                    Training ini bukan tentang menghilangkan emosi "negatif". Bukan bagaimana cara kamu
                  </p>
                  <p>
                    menghilangkan rasa sedih, marah, takut, stress, benci, lelah dan khawatir
                  </p>
                  <p>
                    Tapi soal <b>mengendalikan dan memanfaatkan emosi</b> yang kamu punya. Yang awalnya kamu
                  </p>
                  <p>
                    kira menghambat tapi justru berguna membantumu mencapai tujuan apapun hanya karena
                  </p>
                  <p>
                    kamu <b>tau "cara pakainya"..</b>
                  </p>
                  <p>
                    Cari tahu cara mengendalikan dan memanfaatkan emosi yang kamu punya di Online Training ini!
                  </p>
                </div>
                <div className="_btn-wl-em">
                  <Button variant="primary" size="md" onClick={handleShow}>Ya Saya Mau Video Gratisnya</Button>
                </div>
              </Col>
            </Row>

            <Row className="__margin-top-bottom">
              <Col lg={12}>
                <div className="__guide-em-text">
                  <p className="text-center">
                    <b>Bagaimana jika Ada SATU PANDUAN yang</b> bisa kamu pelajari agar <b>mampu MEMILIH emosi yang tepat sesuai kebutuhan</b> semudah kamu memilih warna baju yang ingin kamu pakai.
                  </p>
                </div>
              </Col>
              <Col lg={12}>
                <div className="__img-logo-gradien-em">
                  <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Emotional-Mastery-Logo-Tagline-black.png" />
                </div>
              </Col>
              <Col lg={12}>
                <div className="__guide-em-text">
                  <p className="text-center">
                    Perkenalkan Emotional Mastery
                  </p>
                  <p className="text-center">
                    Sebuah online training yang bisa kamu gunakan sebagai panduan untuk mengendalikan dan memanfaatkan emosi yang kamu punya. Yang awalnya kamu kira menghambat tapi justru berguna membantumu mencapai tujuan apapun hanya karena kamu tau “cara pakainya”.
                  </p>
                  <p className="text-center">
                    Bayangkan betapa nikmatnya kamu <b>bisa menyampaikan emosi mu</b> terhadap pasangan <b>tanpa menyakiti perasaannya?</b>
                  </p>
                  <p className="text-center">
                    Bayangkan betapa nikmatnya menjalani hubungan <b>minim pertengkaran</b> karena kamu bisa mengendalikan dan memanfaatkan emosi yang kamu punya
                  </p>
                  <p className="text-center">
                    Emotional Mastery hanya diadakan 1 tahun sekali dan dimulai tanggal <b>11 September  2021</b>
                  </p>
                  <p className="text-center">
                    Sebanyak <b>1.632+</b> alumni kami mengaku terkesan dengan teknik di Love Training ini serta merasakan perubahan dan manfaat dalam kehidupan romansanya.
                  </p>
                </div>
                <div className="_btn-wl-em d-flex justify-content-center">
                  <Button variant="primary" size="md" onClick={handleShow}>Ya Saya Mau Video Gratisnya</Button>
                </div>
              </Col>
            </Row>

          </Container>
        </LazyLoad>
        {/* End section two */}
        {/* Section five */}
        <LazyLoad>
          <div className="jumbroton em-bg-gradient">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="__em-logo-lc">
                    {/* set logo love coach */}
                    <div className="d-flex justify-content-center">
                      <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-lc.png" />
                    </div>
                    {/* end set logo love coach */}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__em-logo-emotional">
                    <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-em.png" />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__em-text-join-class">
                    <h2 className="text-center text-white pt-3">Apa saja yang Kamu pelajari setelah ikut training ini?</h2>
                  </div>
                  <Container>

                    <Row className="mt-5">
                      <Col lg={4}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title className="text-center"><b>Secret #1</b></Card.Title>

                            <Card.Text>
                              <p className="text-center">
                                Cara berdamai dengan emosi terpendam
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title className="text-center"><b>Secret #2</b></Card.Title>

                            <Card.Text>
                              <p className="text-center">
                                Benarkah wanita lebih sulit mengendalikan emosi?
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title className="text-center"><b>Secret #3</b></Card.Title>

                            <Card.Text>
                              <p className="text-center">
                                5 Step mengendalikan emosi
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="__price-diskon-wl-em">
                          <p className="text-white text-center">
                            <b>Diskon 67%</b> untuk Tiket VIP Online Training Emotional Mastery, <b>HANYA</b> diberikan bagi mereka yang join waiting list
                          </p>
                        </div>
                        <div className="_btn-wl-em d-flex justify-content-center">
                          <Button variant="primary" size="md" onClick={handleShow}>Ya Saya Mau Video Gratisnya</Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>

            </Container>
          </div>
        </LazyLoad>
        <LazyLoad>
          <Container>
            <Row className="__margin-top-bottom mb-5">
              <Col lg={12}>
                <div className="__wrappe-who-pricing">
                  <h3 className="text-center">
                    Lihat, dengar dan rasakan testimoni jujur dari alumni online training sebelumnya…
                  </h3>
                </div>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Sekarang aku lebih bisa mengontrol sikap ketika bertemu pria yang aku suka sehingga aku tidak terlalu bucin, dan aku selalu ingat kata Coach Jose “jangan menginvestasikan lebih besar dari apa yang dia investasikan”</p>
                      <p className="text-center"><b>-Fitri</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">“HARUS IKUT! Membuka wawasan dan kesadaran banget untuk modal kita membangun hubungan romansa, jadi gak sabar buat praktek.”</p>
                      <p className="text-center"><b>-Indah Rianti</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">“Paradigma yang salah jadi terbuka dan lebih optimis menjalani hidup romansa ke depannya”</p>
                      <p className="text-center"><b>-Felicia</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">Mindset saya tentang cinta, pria, jodoh dan emosi berubah. Sekarang saya lebih merasa terarah untuk hal-hal tersebut”</p>
                      <p className="text-center"><b>-Khonita</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">“Saya jadi mengerti mengapa saya gagal lanjut hingga komitmen di relasi sebelumnya.</p>
                      <p className="text-center"> Saya belajar banyak banget dari training ini. Saya juga terapkan ilmu-ilmu baru yang saya dapat dari training tersebut dan saya merasakan perubahan dari dalam diri saya untuk merasa HAPPY & ENOUGH with myself walaupun saat ini sedang single. </p>
                      <p className="text-center">Saya merasa MUCH UPGRADED dan tahu bagaimana nantinya bila sudah memiliki calon pasangan potensial lagi supaya bisa men-TRIGGER dia untuk mau dan rela berkomitmen dengan saya. :)”</p>
                      <p className="text-center"><b>-Rahma</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">“Gak perlu ragu dan sebagainya karena ini sebuah investasi yang sangat berharga buat diri sendiri. Jangan pelit sama diri sendiri, ayo mulai sayangi diri kamu. Ini tidak hanya applicable untuk romansa, tapi untuk networking dan sebagainya. Dapet banget pelajaran hidupnya. Totally worth it! :) “</p>
                      <p className="text-center"><b>-Lidya</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">“Coba dulu ikutan, lalu coba praktekkan.. Walaupun nantinya belum bisa dipakai untuk saat ini, bisa jadi bekal untuk di kesempatan nanti.. Dan ini bukan cuma bekal untuk proses sebelum menikah, bahkan bisa dipakai ketika sudah menikah.”</p>
                      <p className="text-center"><b>-Indah</b></p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="__em-section-any-problem __wl-margin-bottom">
              <Col lg={12} xs={12}>
                <h2 className="text-center">Kini Saatnya Kamu Berhenti Dari Kesalahan:</h2>
              </Col>
              <div className="__em-mx-section">
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Terjebak dari hubungan yang penuh drama dan jadi bucin</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p> Merasa tertekan dan gak waras menghadapi masalah yang kamu punya</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Mengungkapkan emosi secara meledak-ledak namun hanya memperburuk keadaan</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Kebingungan mengendalikan emosi yang tidak menentu</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Memendam perasaan dan berharap keajaiban mengubah keadaan</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Ekspektasi yang hanya berujung kekecewaan</p>
                  </Col>
                </Row>
                <Col lg={12} xs={12}>
                  <p className="text-center pt-5">
                    Jika kamu mulai merasa lelah dengan masalah yang itu itu aja, ingin kualitas hidup yang bahagia. Maka sekarang kamu mulai sadar kualitas emosi kamu tidak sesuai dengan kualitas hidupmu.
                  </p>
                  <p className="text-center">
                    Jadi tidak ada lagi alasan untuk menunda-nunda join <b>Online Training
                      Emotional Mastery</b>
                  </p>
                  <p className="text-center">
                    <b>So, sebelum menyesal Klik Join Waiting List sekarang!</b>
                  </p>
                </Col>
                <Col lg={12}>
                  <div className="_btn-wl-em d-flex justify-content-center">
                    <Button variant="primary" size="md" onClick={handleShow}>Ya Saya Mau Video Gratisnya</Button>
                  </div>
                </Col>
              </div>
            </Row>

            <Row className="__wl-margin-bottom">
              <Col lg={12}>
                <div className="__text-fb-warning">
                  <p className="text-center">
                    Situs ini bukan bagian dari Facebook atau Facebook Inc. Selain itu, situs ini tidak berkaitan sama sekali dengan situs Facebook dalam hal apapun. FACEBOOK adalah merek dagang dari FACEBOOK, Inc.

                    This site is not a part of the Facebook website or Facebook Inc. Additionally, this site is not endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </LazyLoad>
        {/* end Section five */}
        <Modal show={show} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title >
              <b>Join Waiting List</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <p className="text-center text __modal-pop-waitinglist">
                  <b>Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 30 Agustus 2021 - 3 Video Series dan Diskon  67% Special Offer Emotional Mastery</b>
                </p>
              </Col>
              <Col lg={12}>
                <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
                  <input type="hidden" name="u" value="92" />
                  <input type="hidden" name="f" value="92" />
                  <input type="hidden" name="s" />
                  <input type="hidden" name="c" value="0" />
                  <input type="hidden" name="m" value="0" />
                  <input type="hidden" name="act" value="sub" />
                  <input type="hidden" name="v" value="2" />
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Nama Lengkap*</Form.Label>
                    <Form.Control type="text" controlId="fullname" name="fullname" placeholder="Masukan Nama Lengkap" required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Aktif*</Form.Label>
                    <Form.Control type="text" controlId="email" name="email" placeholder="Masukan Email Aktif" required />
                  </Form.Group>

                  <fieldset>
                    <Form.Group as={Row} className="mb-4">
                      <Form.Label as="legend" column sm={3}>
                        Gender*
                      </Form.Label>
                      <Col sm={3} className="mt-2">
                        <Form.Check
                          type="radio"
                          label="Female"
                          name="field[8]"
                          id="field_8Female"
                          value="Female"
                          required
                        />
                      </Col>
                      <Col sm={3} className="mt-2">
                        <Form.Check
                          type="radio"
                          label="Male"
                          name="field[8]"
                          id="field_8Male"
                          value="Male"
                          required
                        />
                      </Col>
                    </Form.Group>
                  </fieldset>

                  <div className="d-flex justify-content-center _btn-wl-em">
                    <Button variant="primary" type="submit">
                      Ya Saya Mau Video Gratisnya
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col lg={12}>
                <p className="text-center">©
                  {` `}
                  LoveCoach.id {new Date().getFullYear()}</p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  )
}

